// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.


$(document).ready(function () {
    // $("body").css("overflow-x", "hidden");

    jQuery("#start-journey-button").click(function () {
        if (jQuery('#basic-offer-content:visible').length) {
            $("#basic-offer-image").attr("src", "/assets/images/children/back1.png");
            jQuery('#basic-offer-content').hide();
            jQuery('#advance-main').show();
        } else {
            $("#basic-offer-image").attr("src", "/assets/images/children/front1.png");
            jQuery('#advance-main').hide();
            jQuery('#basic-offer-content').show();
        }
    });
    jQuery("#advance-journey-button").click(function () {
        if (jQuery('#advance-offer-content:visible').length) {
            $("#advance-offer-image").attr("src", "/assets/images/children/back2.png");
            jQuery('#advance-offer-content').hide();
            jQuery('#basic-main').show();
        } else {
            $("#advance-offer-image").attr("src", "/assets/images/children/front2.png");
            jQuery('#basic-main').hide();
            jQuery('#advance-offer-content').show();
        }
    });

    // $(".nav a").on("click", function () {
    //     $(".nav").find(".active").removeClass("active");
    //     $(this).parent().addClass("active");
    // });
});


function toogeMessage(show) {
    if (!show) {
        jQuery('#main-message-content').hide(50);
        jQuery('#main-message').hide(600);
    } else {
        jQuery('#main-message-content').delay(500).show(50);
        jQuery('#main-message').show(600);
    }
}

jQuery('#offer-button').click(function () {
    document.location.href = '/offer';
});

$(document).click(function (event) {
    if (!$(event.target).closest('#main-message').length && jQuery('#main-message:visible').length) {
        jQuery('#main-message').removeAttr('x-source');
        jQuery('#main-message-content').hide(50);
        jQuery('#main-message').hide(600);
    }
});

jQuery('#join-us-button').click(function (event) {
    event.stopPropagation();
    var attr = jQuery('#main-message').attr('x-source');
    jQuery('#main-message').attr('x-source', 'join-us')
    if (typeof attr === typeof undefined || attr === false || attr != 'join-us') {
        jQuery('#main-message').css('background-color', '#0071b9');
        jQuery('#main-message-content').html("napisz do nas<br/>" +
            "            <a href=\"mailto:info@jump-camp.pl\">info@jump-camp.pl</a><br/>" +
            "            lub<br/>" +
            "            zadzwoń<br/>" +
            "<a href='tel:+48513158285'>+48 513 158 285</a><br/>" +
            "<a href='tel:+48505675235'>+48 505 675 235</a>");
        toogeMessage(true);
    } else {
        toogeMessage(false);
        jQuery('#main-message').removeAttr('x-source');
        return;
    }
});

jQuery('#near-trening-button').click(function (event) {
    event.stopPropagation();
    var attr = jQuery('#main-message').attr('x-source');

    jQuery('#main-message').attr('x-source', 'near-trening')
    if (typeof attr === typeof undefined || attr === false || attr != 'near-trening') {
        jQuery('#main-message').css('background-color', '#e52c8a');
        jQuery('#main-message-content').html(
            "Sprawdzam... :)");
        $.getJSON('api/Info/nextTrainings', function (data) {
            // JSON result in `data` variable
            data["content"];
            jQuery('#main-message-content').html(data["content"]);
        });
        toogeMessage(true);

    } else {
        toogeMessage(false);
        jQuery('#main-message').removeAttr('x-source');
        return;
    }

});



// $('#mynavBar2').on('activate.bs.scrollspy', function () {
//     $('#test3').toggleClass('btn-warning');

// }
// );
// $('#mynavBar21').on('activate.bs.scrollspy', function () {
//     $('#test3').toggleClass('btn-warning');

// }
// );